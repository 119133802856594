/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'
import questionsData from '../assets/ham.json'

Vue.use(Vuex)

// Fetch question index
fb.questionIndexCollection.doc("index").get().then((response) => {
  // Update store
  store.commit('setQuestionIndex', response.data())
})


const store = new Vuex.Store({
  state: {
    userProfile: {},
    questions:[],
    exam:[],
    questionIndex:[],
    completedExams: [],
    alert: {message:'', code:''},
  },
  getters: {
    results: state => {
      var ret = []
      var i
      for (i = 0; i <  state.completedExams.length; i++) {
        if (typeof state.completedExams[i].results != "undefined")
          ret.push(state.completedExams[i].results)
      }
      return ret
    },
    getExamById: (state) => (id) => {
      return state.completedExams.find(exam => exam.id == id)
    },
    progress: (state, getters) => {
      var prog = {
        "B-001": { "score":0, "outOf": 0, "percent":0, "title": "Regulations & Policies", "section":"B-001"},
        "B-002": { "score":0, "outOf": 0, "percent":0, "title": "Operating & Procedures", "section":"B-002"},
        "B-003": { "score":0, "outOf": 0, "percent":0, "title": "Station Assembly & Safety", "section":"B-003"},
        "B-004": { "score":0, "outOf": 0, "percent":0, "title": "Circuit Components", "section":"B-004"},
        "B-005": { "score":0, "outOf": 0, "percent":0, "title": "Basic Electronics & Theory", "section":"B-005"},
        "B-006": { "score":0, "outOf": 0, "percent":0, "title": "Feedlines & Antenna Systems", "section":"B-006"},
        "B-007": { "score":0, "outOf": 0, "percent":0, "title": "Radio Wave Propagation", "section":"B-007"},
        "B-008": { "score":0, "outOf": 0, "percent":0, "title": "Interference & Suppression", "section":"B-008"},
        "Total": { "score":0, "outOf": 0, "percent":0, "title": "Overall average", "section":"all"},
      }
      getters.results.forEach(exam => {
          var questionKeys = Object.keys(exam)
          questionKeys.forEach(qkey => {
            if (qkey !== "id"){
              // add score
              var pr = prog[qkey.substring(0,5)];
              if (pr !== 'undefined'){
                pr.score = pr.score + exam[qkey];
                // Increment question count
                pr.outOf = pr.outOf + 1;
                pr.percent = ((pr.score/pr.outOf) * 100).toFixed(0);
                // Update the overall total
                prog["Total"].score = prog["Total"].score + exam[qkey];
                prog["Total"].outOf = prog["Total"].outOf + 1;
                prog["Total"].percent = ((prog["Total"].score/prog["Total"].outOf) * 100).toFixed(0);
              }              
            }
          })
      });
      return prog
    }
  },
  mutations: {
    setAlert(state, val) {
      state.alert = val
    },
    setClearExam(state) {
      state.exam = []
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setQuestions(state, val) {
      state.questions = val
    },
    setExam(state, val) {
      state.exam = val
    },
    setQuestionIndex(state, val) {
      state.questionIndex = val
    },
    setCompletedExams(state, val) {
      state.completedExams = val
    }
  },
  actions: {
    setAlert({ commit }, payload) {
      commit('setAlert', payload)
    },
    clearAlert({ commit }) {
      commit('setAlert', {message:'', code:''})
    },
    clearExam({ commit }) {
      commit('setClearExam')
    },
    async feedback({ dispatch }, payload) {
      const user = fb.auth.currentUser
      // update user object
      const docRef = await fb.mailCollection.add({
        to: ['joeradman@gmail.com'],
        message: {
          subject: 'HAM Test Feedback',
          html: "From: " + user.email + '<br />' + payload,
        }
      }).then(() => console.log('Queued email for delivery!'));
    },
    async login({ dispatch }, form) {
      // sign user in
      await fb.auth.signInWithEmailAndPassword(form.email, form.password).then((userCredential) => {
        // Signed in - fetch user profile and set in state
        //console.log("then", userCredential)
        dispatch('fetchUserProfile', userCredential.user)
        //console.log("post")
      })
      .catch((error) => {
        //var errorCode = error.code
        //var errorMessage = error.message
        dispatch('setAlert', {message: error.message, code: error.code})
      });
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        name: form.name,
        title: form.title
      })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()

      // set user profile in state
      commit('setUserProfile', userProfile.data())

      //
      // realtime firebase
      const userId = fb.auth.currentUser.uid
      fb.db.collection('users').doc(userId).collection('exams').onSnapshot(snapshot => {
        let resultsArray = []

        snapshot.forEach(doc => {
          let post = doc.data()
          post.id = doc.id

          resultsArray.push(post)
        })
        store.commit('setCompletedExams', resultsArray)
      })

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})

      // redirect to home page
      router.push('/')
    },
    async createQuestions({ state, commit }, post) {
      // create post in firebase
      //console.log("Questions:", questionsData.length)
      questionsData.forEach(async function(q,index) {
        //console.log("index:", index)
        await fb.questionsCollection.doc(q.questionid).set({
          createdOn: new Date(),
          questionid: q.questionid,
          questionenglish: q.questionenglish,
          correctanswerenglish: q.correctanswerenglish,
          incorrectanswer1english: q.incorrectanswer1english,
          incorrectanswer2english: q.incorrectanswer2english,
          incorrectanswer3english: q.incorrectanswer3english,
          questionfrench: q.questionfrench,
          correctanswerfrench: q.correctanswerfrench,
          incorrectanswer1french: q.incorrectanswer1french,
          incorrectanswer2french: q.incorrectanswer2french,
          incorrectanswer3french: q.incorrectanswer3french
        })
      })
    },
    async createQuestionIndex({ state, commit }, post) {
      // create post in firebase
      //console.log("Questions:", questionsData.length)
      var array = []
      questionsData.forEach(async function(q,index) {
        //console.log("index:", index)
        array.push(q.questionid)
      })
      await fb.questionIndexCollection.doc("index").set({
        ids: array
      })


    },
    async fetchPracticeExam({ state, commit }, payload) {
      // payload { count: 10, section: 'all' or "B-001" }
      if (payload == null){
        payload = { count: 10, section: "all"}
      }

      // shuffle question index
      var subset = state.questionIndex.ids.sort(() => 0.5 - Math.random()).slice(0,payload.count)

      // If not all - limit questions to target section
      if (payload && payload.section != "all"){
        subset = state.questionIndex.ids.filter( section => section.substring(0,5) == payload.section ).sort(() => 0.5 - Math.random()).slice(0,payload.count)
      }
      
      var shuffle = function(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }

        return array;
      }

      // Store the questions
      let exam = []
      // Fetch each question
      for (const questionId of subset) {
        //console.log("fetching question:", questionId)
        const doc = await fb.questionsCollection.doc(questionId).get()
        var document = doc.data()
        // Randomize the questions
        document["choicesEng"] = shuffle([document.correctanswerenglish,document.incorrectanswer1english,document.incorrectanswer2english,document.incorrectanswer3english])
        document["choicesFr"] = shuffle([document.correctanswerfrench,document.incorrectanswer1french,document.incorrectanswer2french,document.incorrectanswer3french])

        exam.push(document)        
      }

      // Update store
      //console.log("exam exam:", exam)
      commit('setExam', exam)
    },
    deleteExam({ dispatch }, payload) {
      const userId = fb.auth.currentUser.uid
      // update user object
      fb.usersCollection.doc(userId).collection('exams').doc(payload).delete().then(() => {
        console.log("Document successfully deleted!");
        dispatch('fetchUserProfile', { uid: userId }); // Reset exams users account etc

    }).catch((error) => {
        console.error("Error removing document: ", error);
    });

    },

    async saveExam({ dispatch }, payload) {
      const userId = fb.auth.currentUser.uid
      // update user object
      const docRef = await fb.usersCollection.doc(userId).collection('exams').add(payload)

      dispatch('fetchUserProfile', { uid: userId })
    },
    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      // update user object
      const userRef = await fb.usersCollection.doc(userId).update({
        name: user.name,
        title: user.title
      })
      //console.log(userRef);

      dispatch('fetchUserProfile', { uid: userId })

 
    }
  }
})

export default store