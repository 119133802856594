import firebase from "firebase/app"
import '@firebase/auth'
import '@firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyBy1llXQu0dPNt5MICYVVWrnqIfzlnv-ik',
  authDomain: '',
  databaseURL: '',
  projectId: 'hamradio-70ef2',
  storageBucket: '',
  messagingSenderId: '736467703762',
  appId: '1:736467703762:web:abf1c9b5a83a6b17159a0b'
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const questionsCollection = db.collection('questions')
const questionIndexCollection = db.collection('questionIndex')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  questionsCollection,
  questionIndexCollection,
}