<template>
    <component v-bind:is="currentComponent"></component>
</template>

<script>
import { mapState } from 'vuex'

export default {
    components: {
        Welcome: () => import ( /* webpackChunkName: "Exam" */ "@/pages/Welcome.vue"),
        PracticeExams: () => import ( /* webpackChunkName: "Exam" */ "@/pages/PracticeExams.vue"),
    },
    data() {
        return {
            loading: false,
            selection: 1,
        };
    },
    computed: {
        // map this.count to store.state.count
        ...mapState(['userProfile']),
        isLoggedIn() {
            return Object.keys(this.userProfile).length > 1
        },
        currentComponent() {
            return this.isLoggedIn ? "PracticeExams" : "Welcome";
        }
    },
    methods: {},
    filters: {},
};
</script>

<style lang="scss" scoped>

</style>