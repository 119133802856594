<template>
    <v-container fluid>
        <v-row> 
            <v-col cols="12">
                <v-card >
                    <br />
                    <v-card-title class="mx-12 justify-center text-center">Test your HAM knowledge with a practice test of 10 random test questions from the<br />Canadian radio test bank. Good luck!</v-card-title>

                    <v-card-actions >
                        <v-dialog v-model="dialog" hide-overlay scrollable max-width="600px" transition="dialog-bottom-transition">
                            <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="questions()"
                                            class="mx-auto"
                                          >
                                            Try a free practice radio test
                                          </v-btn>
                            </template>
                            <Exam v-if="showExam" @closeExam="closeExam" />
                        </v-dialog>
                    </v-card-actions>
                    <v-card-title class="mx-12 my-4 justify-center">Now <a href="/" class="mx-2">create an account</a> &nbsp;and track your progress and generate section specific tests.</v-card-title>
                    <v-img
                        height="450"
                        src="@/assets/hero.jpg"
                        ></v-img>
                </v-card>
            </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { commentsCollection } from '@/firebase'
// import moment from 'moment'

export default {
    components: {
        Exam: () =>
            import ( /* webpackChunkName: "Exam" */ "@/components/Exam.vue"),
    },
    data() {
        return {
            dialog: false,
        }
    },
    computed: {

        results: function() {
            return this.$store.getters.results;
        },
        showExam: {
            get() {
                return this.$store.state.exam.length > 0;
            },
            set() {
                // do nothing
            }
        }
    },
    methods: {
        closeExam() {
            this.dialog = false
            this.$store.dispatch('clearExam')
        },
        examKeys(exam) {
            var questionKeys = Object.keys(exam)
            return questionKeys.filter(key => key !== 'id').sort()
        },
        questions() {
            //this.$store.dispatch('fetchPracticeExam')
            //this.$store.dispatch('createQuestions')
            this.$store.dispatch('fetchPracticeExam')
        },
    },
    filters: {

    }
}
</script>

<style lang="scss" scoped>

</style>