<template>
    <v-app id="inspire" :class="currentRouteName == 'Welcome' || currentRouteName == 'Pricing' ? 'welcome' : '' " >
        <v-app-bar app class="light-blue white--text elevation-0">
            <!--
                              <v-toolbar-title  class="text-h5" v-if="currentRouteName !== 'Login'">{{currentRouteName}}</v-toolbar-title>
                              <v-toolbar-title  class="text-h5" v-if="currentRouteName == 'Login'">Train for the Amateur Radio Certification</v-toolbar-title>
                              -->
            <router-link to="/">
                <v-btn text color="white">
                    <v-icon>mdi-radio</v-icon> Ham Test</v-btn>
            </router-link>
            <router-link to="Trial" v-if="isLoggedIn==false">
                <v-btn text color="white">
                    <v-icon small>mdi-marker-check</v-icon>Practice</v-btn>
            </router-link>
            <router-link to="Equipment">
                <v-btn text color="white">
                    <v-icon>mdi-cellphone-basic</v-icon>Equipment</v-btn>
            </router-link>
            <router-link to="Pricing">
                <v-btn text color="white">
                    <v-icon small>mdi-currency-usd</v-icon><strike>Pricing</strike><span class="pl-2">Free</span></v-btn>
            </router-link>
            <!--
            <router-link to="Clubs">
                <v-btn text color="white">
                    <v-icon small>mdi-map</v-icon>Clubs</v-btn>
            </router-link>
            <router-link to="Resources">
                <v-btn text color="white">
                    <v-icon small>mdi-book-open-variant</v-icon>Resources</v-btn>
            </router-link>
            
            -->
    
            <v-spacer></v-spacer>
            <Feedback v-if="isLoggedIn" />
            <v-btn @click="logout()" text small color="white" v-if="isLoggedIn">Logout</v-btn>
            <router-link to="Profile" v-if="isLoggedIn">
                <v-btn icon outlined small color="white">
                    <v-icon to="Profile">mdi-account</v-icon>
                </v-btn>
            </router-link>
        </v-app-bar>
        <v-main>
            <v-container>
                <v-snackbar v-model="showAlert" centered timeout=10000 color="primary" text outlined>
                    {{ alert.message }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                        color="primary"
                        text
                        v-bind="attrs"
                        @click="showAlert = false"
                        >
                        Close
                        </v-btn>
                    </template>
                </v-snackbar>
                <router-view />
                
            </v-container>
        </v-main>
        <v-footer>
            <p class="text-center">Copyright &copy; 2021, Jozo Radman. All Rights Reserved. <router-link to="Terms">Terms</router-link></p>
            </v-footer>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'App',

    components: {
        Feedback: () => import ( /* webpackChunkName: "Exam" */ "@/components/Feedback.vue"),
    },
    computed: {
        // map this.count to store.state.count
        ...mapState(['userProfile', 'alert']),
        showAlert: {
            get() {
                return this.$store.state.alert.message.length > 0
            },
            set(value) {
                console.log(value)
                this.$store.dispatch('clearAlert')
            }
        },
        isLoggedIn() {
            return Object.keys(this.userProfile).length > 1
        },
        currentRouteName() {
            return this.$route.name;
        }
    },
    data: () => ({
        drawer: null,
        
    }),
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        navTo(route) {
            this.router.push(route)
        },
    }
};
</script>

<style lang="scss" >
.welcome {
  background: url('~@/assets/onair.jpg')
    no-repeat center center fixed !important;
  background-size: cover;
}
</style>