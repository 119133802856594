<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6" class="mx-auto">
            <v-card>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="headline">
                            Account profile
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
    
                <v-card-text>
                    <p>This site is still in early development. Currently there is nothing to configure here. In the future there are plans to have account management functions available here.</p>
                    Name: {{ userProfile.name }}<br />
                </v-card-text>
                <v-divider></v-divider>
    
                <v-card-actions>
                    <v-btn text>
                        Logout
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
    components: {},
    data() {
        return {

        };
    },
    computed: {
        ...mapState(['userProfile'])
    },
    methods: {
        updateProfile() {
            this.$store.dispatch('updateProfile', {
                name: this.name !== '' ? this.name : this.userProfile.name
            })

        },
        logout() {
            this.$store.dispatch('logout')
        },
    },
    filters: {},
};
</script>

<style lang="scss" scoped>

</style>