<template>
    <v-container fluid>
        <v-row align="top" justify="center" class="mt-14">
            <v-col cols="12">
              <v-card flat>
                <v-card-text>
                    <div class="text-h3 mb-6 mt-6">Simple pricing model. Free.</div>
                    <div class="body-1">This site is free. Hopefully we can keep it that way. Our goal is to help fellow Canadian radio enthusiasts get their license and get on the air. We do have ads on the site and we do have affiliate links. If you find value in this website,
                        then we hope you don't mind the ads. If you visit Amazon via the equipment links and make a purchase, then we get a little kick back from Amazon. Every little bit helps keep the lights on.</div>
                    <div class="body-1">Enjoy the site, study hard, and we hope to hear you on the air real soon.</div>
                    <v-chip class="mt-5" x-large>
                        <span class="text-h4 mb-6 mt-6 grey--text text--darken-1">$0 plan </span>
                    </v-chip>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import { commentsCollection } from '@/firebase'

export default {
    components: {},
    data() {
        return {
            loading: false,
            selection: 1,
        };
    },
    computed: {},
    methods: {
        reserve() {
            this.loading = true;

            setTimeout(() => (this.loading = false), 2000);
        },
    },
    filters: {},
};
</script>

<style lang="scss" scoped>

</style>