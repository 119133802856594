<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-card-text>
                        <p>I've started to compile a list of resources. In the meantime, here's a list of Ontario clubs I've pulled from <a href="https://www.rac.ca/amateur-radio-courses/#ON">RAC - Radio Amateurs of Canada</a>. I'll be compiling more information
                            soon. You can visit RAC for the complete list of Canadian clubs.</p>
                        <h3>
                            <a id="ON"></a> Ontario
                        </h3>
                        <h4>Barrie</h4>
                        <div>
                            <p>
                                The
                                <a href="http://www.barriearc.com/" target="_blank" rel="noopener">Barrie Amateur Radio Club</a> offers a Basic Qualification Course once each year starting in January or early February.
                            </p>
                            <p>The course consists of 10 evening classes approximately 2 to 2-1/2 hours long starting at 7 pm on Wednesday evenings. The final exam is given on the 11th evening.</p>
                            <p>The total cost is $100 which includes the latest Basic Qualification Study Guide from Coax Publications, a USB memory stick with course notes and much information on Amateur Radio, and the final exam by an ISED Accredited Examiner.</p>
                            <p>Those who successfully pass the exam and receive a call sign will also receive a full membership in the Barrie Amateur Radio Club for the remainder of that membership year.</p>
                            <p>The course is currently being held on Google Meet video conferencing during Covid19.</p>
                            <p>The course is normally held at the Eastview Secondary School (421 Grove Street East) in Barrie, Ontario. Check the club webpage for details.</p>
    
                            <p>
                                <b>Website</b> :
                                <a href="http://www.barriearc.com/" target="_blank" rel="noopener">https://barriearc.com/</a>
                            </p>
                        </div>
                        <h4>Belleville</h4>
                        <p>The Quinte Amateur Radio club offers a course for the Basic Amateur Radio certificate based on demand. Please check the QARC website for information on when and where courses will be held in the Belleville area.</p>
                        <p>
                            <strong>Website:</strong>
                            <a href="http://www.qarc.on.ca/">www.qarc.on.ca</a>
                        </p>
                        <h4>Burlington</h4>
                        <p>Make Amateur Radio Your New Year’s Resolution! The next Burlington Amateur Radio Club (BARC) Course for the Basic certificate starts Wednesday, January 20, 2021. Classes will be held online using Zoom.</p>
                        <p>We will meet online from 7:00 until 9:00. The course meets eight Wednesdays. The last Course meeting night is March 31, 2021.</p>
                        <p>
                            <strong>Cost:</strong> $100 includes: BARC membership, all necessary course materials and the exam.
                        </p>
                        <p>Register early to be included in the course lead-up activities.</p>
    
                        <p>
                            <strong>Website:</strong>
                            <a href="https://www.barc.ca/education" target="_blank" rel="noopener">https://www.barc.ca/education</a>
                        </p>
                        <h4>Kitchener-Waterloo</h4>
                        <p>The Kitchener-Waterloo Amateur Radio Club (KWARC) is pleased to offer a Basic Amateur Radio Course.</p>
                        <p>
                            <strong>Date:</strong> Starting at 7:30 pm on March 24, 2020, and running until June 9, 2020.
                        </p>
                        <p>
                            <strong>Place:</strong> St John Ambulance, 250 Gage Ave, Kitchener
                        </p>
                        <p>This course will be a condensed version of the full course offered by various clubs. Participants will be expected to study course materials in advance independently, as well as attend the classroom sessions. The classroom program
                            would consist of an introduction to Amateur Radio, meet the instructors, introduction to Internet self-study, and a discussion of the timetable on Tuesday, March 24.</p>
                        <p>On subsequent nights there would be various hands-on demonstrations, a practice exam on Tuesday May 26 and a Government Examination conducted by the Club Examiner on June 9. There would be seven classroom sessions from 7:30 pm to 10
                            pm, held at the St John Ambulance facility, 250 Gage Avenue, Kitchener.</p>
                        <p>Course instructors will be Roger Sanderson, VE3RKS, Gord Hayward VE3EOS, David Sullivan, VE3RBA, Tim Vegh, VA3TMV, Jonathan Fritz, VA3JFZ, Vic DiCiccio, VE3YT, Dan Damianoff, VA3IDD,and Rick Goetze, VE3ZUP.</p>
                        <p>
                            <strong>Eligibility:</strong> This course is open to anyone; however, enrollment is limited. For practical reasons, the class size is capped at 30 students.
                        </p>
                        <p>
                            <strong>Cost/Registration:</strong> Free with a $25 one-year membership in KWARC. (Normal membership is $36.) Bring cash or cheque payable to &#8220;KW Amateur Radio Club&#8221; to the first meeting.
                        </p>
                        <p>Included: Free parking, 10 weeks training sessions including exam. One-year Associate Club Membership</p>
                        <p>
                            Not Included: Course Material: Check with the instructor for which
                            <a href="https://wp.rac.ca/study-guides-2/" target="_blank" rel="noopener">Study Guide</a> to order through the RAC website.
                        </p>
    
                        <p>
                            <strong>Website:</strong>
                            <a href="http://www.kwarc.org/kw-class.html">www.kwarc.org/kw-class.html</a>
                        </p>
                        <h4>Mississauga</h4>
                        <p>
                            The Mississauga Amateur Radio Club VE3MIS holds a course each year in Mississauga to provide you with the knowledge to pass your Innovation, Science and Economic Development Canada (formerly Industry Canada) Basic Qualification Amateur Radio examination
                            to obtain your Amateur Radio certificate.
                            <strong>Basic courses</strong> usually start in September and run until mid-December.
                        </p>
                        <p>
                            An
                            <strong>Advanced Course</strong> is usually held the beginning in January and runs until March. This course will provide you with all of the information required to obtain your optional Advanced endorsement. The club also holds
                            an optional
                            <strong>Morse Code Course</strong> leading to your CW endorsement if sufficient students enroll.
                        </p>
                        <p>Accredited Innovation, Science and Economic Development Canada examiners are available in the club to administer the examinations as part of the class. RAC Study Guides are used for the Basic and Advanced Courses.</p>
                        <p>For more information on time, location, study materials and fees, visit our website.</p>
                        <p>You may also write us at:</p>
                        <p>
                            Mississauga ARC
                            <br/> Attention: Education Manager
                            <br/> P.O. Box 2003
                            <br/> Square One Post Office
                            <br/> Mississauga, ON L5B 3C6
                        </p>
                        <p>
                            Website:
                            <a href="http://www.marc.on.ca/">www.marc.on.ca</a>
                        </p>
                        <h4>Niagara Region</h4>
                        <h4>Niagara Peninsula Amateur Radio Club</h4>
                        <div>
                            <div>Niagara Peninsula Amateur Radio Club offers Basic Qualification training, typically every year, per demand.</div>
                            <div></div>
                            <div>
                                Information may be found at the club&#8217;s website:
                                <a href="https://nparc.ca" target="_blank" rel="noopener" data-saferedirecturl="https://www.google.com/url?q=https://nparc.ca&amp;source=gmail&amp;ust=1607788945481000&amp;usg=AFQjCNHqFZSlU5blURbUZoiZ1b-j4paP1A">https://nparc.ca</a>                            .
                            </div>
                            <div></div>
                        </div>
                        <h4>Ottawa</h4>
                        <p>The Ottawa Valley Mobile Radio Club offers an Amateur Radio course each year for those wishing to acquire a &#8220;Certificate of Proficiency in Amateur Radio&#8221; (Basic Radio Amateur qualification) from Innovation, Science and
                            Economic Development Canada (formerly Industry Canada) to operate an Amateur Radio station. Please see the OVMRC website for information on when and where the courses are held.</p>
                        <p>
                            Website:
                            <a href="http://ovmrc.on.ca/radio-course.html">ovmrc.on.ca/radio-course.htm</a>
                        </p>
                        <h4>Toronto</h4>
                        <p>The Central Toronto Amateur Radio Club offers a variety of Amateur Radio certification courses throughout the year, including certification for the:</p>
                        <p>
                            Basic Qualification
                            <br/> Advanced Qualification
                            <br/> Morse Code Qualification
                        </p>
                        <p>For more information about upcoming courses, please check out the club&#8217;s website at:</p>
                        <p>
                            Website:
                            <a href="http://va3cta.net/pages/get-a-license">http://va3cta.net/pages/get-a-certificate</a>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import { commentsCollection } from '@/firebase'

export default {
    components: {},
    data() {
        return {
            loading: false,
            selection: 1,
        }
    },
    computed: {},
    methods: {
        reserve() {
            this.loading = true

            setTimeout(() => (this.loading = false), 2000)
        },
    },
    filters: {}
}
</script>

<style lang="scss" scoped>

</style>