import Vue from 'vue'
import VueRouter from 'vue-router'
import Trial from '../pages/Trial.vue'
import Equipment from '../pages/Equipment.vue'
import Resources from '../pages/Resources.vue'
import Clubs from '../pages/Clubs.vue'
import Pricing from '../pages/Pricing.vue'
import Profile from '../pages/Profile.vue'
// import Welcome from '../pages/Welcome.vue'
import Landing from '../pages/Landing.vue'
import Terms from '../pages/Terms.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Landing,
    meta: {
      requiresAuth: false,
      title: "Welcome"
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      requiresAuth: false,
      title: "Terms"
    }
  },
  {
    path: '/trial',
    name: 'Trial',
    component: Trial,
    meta: {
      requiresAuth: false,
      title: "Practice Exams"
    }
  },
  {
    path: '/equipment',
    name: 'Equipment',
    component: Equipment,
    meta: {
      requiresAuth: false,
      title: "Equipment"
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources,
    meta: {
      requiresAuth: false,
      title: "Resources"
    }
  },
  {
    path: '/clubs',
    name: 'Clubs',
    component: Clubs,
    meta: {
      requiresAuth: false,
      title: "Clubs"
    }
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    meta: {
      requiresAuth: false,
      title: "Pricing"
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      title: "Profile"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: "Login"
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      title: "Settings"
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
