<template>
    <div style="height:500px;">
        <googlemaps-map :center.sync="center" :zoom.sync="zoom" :options="mapOptions" @idle="onIdle" @click="onMapClick">
    
            <!-- User Position -->
            <googlemaps-user-position @update:position="setUserPosition" />
    
            <googlemaps-marker v-for="marker of markers" :key="marker._id" :label="{
                  color: marker === currentmarker ? 'white' : 'black',
                  fontFamily: 'Material Icons',
                  fontSize: '20px',
                  text: 'star_rate',
                }" :position="marker.position" @click="selectMarker(marker._id)" />
        </googlemaps-map>
    </div>
</template>

<script>
// import { commentsCollection } from '@/firebase'

export default {
    components: {},
    data() {
        return {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 12,
            mapOptions: {
                zoom: 8,
                center: { lat: -34.397, lng: 150.644 },
            },
            currentmarker: null,
            markers: [{
                _id: 1,
                position: { lat: 48.864716, lng: 2.349014 }
            }]

        }
    },
    computed: {},
    methods: {
        onIdle() {},
        onMapClick() {},
        setUserPosition() {},
        selectMarker(position) {
            console.log(position)
        }
    },
    filters: {}
}
</script>

<style lang="scss" scoped>
.vue-google-map {
    height: 100%;
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
}
</style>