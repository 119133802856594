<template>
    <v-container fluid>
        <v-row justify="space-between">
            <div class="body-1">Here's a collection of mainly Icom equipment available on Amazon Canada. This is not an endoresement of any of these products, just providing some links to common equipment we're interested in. These are affiliate links. If you'd like to help
                the site, click on a link. You don't have to purchase any of these products specifically. Just clicking on the links helps us out. Thank you.</div>
            <v-card flat v-for="(radio,index) in radios" :key="index">
                <v-card-text v-html="radio"></v-card-text>
            </v-card>
    
        </v-row>
    </v-container>
</template>

<script>
// import { commentsCollection } from '@/firebase'

export default {
    components: {},
    data() {
        return {
            loading: false,
            selection: 1,
            radios: ["<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B0795T625Y&linkId=0a43cf67f5bddad3c1d02e064918bdf6'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B078X7XJ22&linkId=98450d5d92a9f37ab30347c2f006252f'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B0089NL7XY&linkId=4202f87325391197ffacf862f775619b'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B0089NNC82&linkId=7e6927f50fa1e797a2b6755af740add3'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B01C95F56M&linkId=bea3a903a2e2e6d51cd1026b3c87bd8d'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B06Y2697RX&linkId=1bb402ce9fb4e69176c05ef052ca408a'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B017XB4I4M&linkId=02d45d1652bf26872da59fbccb32c101'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B075CTZGLF&linkId=227b0dfe2f0d4444f37a1d9fa7baff98'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B001CF8BIU&linkId=d5a727cb74fc4c2b720e1721193e4e66'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B007470C0A&linkId=313aa779db3a74822f8b908e53f52b3f'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07PQQVPDD&linkId=1738c2044ca2f19b437aac6391da9b5b'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08D8RRNHK&linkId=a931fa94cb9126511e2fe6826d283b43'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B007470C0A&linkId=6551f090e53aaf3ab8cfd3a5c5279f27'></iframe>",
                "<iframe style='width:120px;height:240px;' marginwidth='0' marginheight='0' scrolling='no' frameborder='0' src='//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=canadiantec02-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07GL7K2JX&linkId=882570cf15803df7a3254cb7964a8a58'></iframe>"
            ]
        };
    },
    computed: {},
    methods: {
        reserve() {
            this.loading = true;

            setTimeout(() => (this.loading = false), 2000);
        },
    },
    filters: {},
};
</script>

<style lang="scss" scoped>

</style>